import React from "react";
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
`
const Cursor = styled.div`
    width: 9px;
    height: 18px;
    background-color: #28FDCD;
    box-shadow: 0 0 3px #33FF33, 0 0 10px #00ff66, 0 0 10px #00ff66;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
    @keyframes blink-animation {
        to {
            visibility: hidden;
        }
    }
    @-webkit-keyframes blink-animation {
        to {
            visibility: hidden;
        }
    }
`

const Pre = styled.pre`
    white-space: pre-wrap;
`

const Line = ({text, showCursor}: {text: string, showCursor?: boolean}) => {
    return (
        <Wrapper>
            <Pre>{text}</Pre>{showCursor && <Cursor />}
        </Wrapper>
    )
}

export default Line;