import React from 'react';
import './App.css';
import Terminal from './terminal/components/Terminal';

function App() {
  React.useEffect(() => {
      console.log('the game is afoot\nthere are clues hidden everywhere but not here\nand don\'t look in the source code, that\'s cheating')
  },[])
  return (
    <div>
      <Terminal />
    </div>
  );
}

export default App;
