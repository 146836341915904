export const getMobileOperatingSystem = () => {
    const w = window as any;
    var userAgent = navigator.userAgent || navigator.vendor || w.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const getIsMobile = () => {
    const os = getMobileOperatingSystem();
    return os === "Android" || os === "iOS" || os === "Windows Phone";
}

export const isMobile = getIsMobile();