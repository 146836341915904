import React from "react";
import Line from "./Line";
import styled from 'styled-components'
import { isMobile } from "../utils";

const InputComponent = styled.input`
    opacity: 0;
    position: absolute;
    top: -50px;
`

type Handlers = {
    onEnter: () => void
    onKey: (key: string) => void
}

const VirtualInput = ({onChange, handlers}: {onChange: React.ChangeEventHandler<HTMLInputElement>, handlers: Handlers}) => {
    const ref = React.useRef<HTMLInputElement>(null)
    const handleBlur = () => {
        if (!isMobile) {
            ref.current?.focus()
        }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        handlers.onKey(event.key)
        if (event.key === 'Enter') {
            handlers.onEnter()
        }
    }
    return <InputComponent id="terminal-input" ref={ref} type="text" onBlur={handleBlur} onChange={onChange} autoFocus={!isMobile} onKeyDown={handleKeyDown} />
}

type Props = {
    prefix: string
    onEnter: (value: string) => void
    onKey: (key: string) => void
}
const InputLine = ({prefix, onEnter, onKey}: Props) => {
    const [text, setText] = React.useState<string>('')
    const [inputFocused, setInputFocused] = React.useState<boolean>(!isMobile)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.currentTarget.value)
    }
    const handleEnter = () => {
        onEnter(text)
        setText('')
        ;(document.querySelector('#terminal-input') as HTMLInputElement).value = ''
    }

    React.useEffect(() => {
        window.addEventListener('click', () => {
            const input = document.querySelector('#terminal-input') as HTMLInputElement
            input?.focus()
            setInputFocused(true)
        })
        if (!isMobile) {  
            window.addEventListener('focus', () => {
                const input = document.querySelector('#terminal-input') as HTMLInputElement
                input?.focus()
                setInputFocused(true)
            })
            window.addEventListener('blur', () => {
                setInputFocused(false)
            })
        }
    }, [])

    return (
        <>
            <Line text={`${prefix}${text}`} showCursor={inputFocused} />
            <VirtualInput onChange={handleChange} handlers={{onEnter:handleEnter, onKey}} />
        </>
    )
}

export default InputLine;