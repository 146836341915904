import { LevelHandler } from "./Base"

const DefaultLines = ['Everything you need to know is right here.']
export class StartHandler extends LevelHandler {
    public password = 'gemini'
    public get prefix() {
        return '>'
    }
    public start(): void {
        if (!this.isStarted) {
            this.echo(DefaultLines)
        }
    }
    public onKey(key: string): void {
        if (key === 'ArrowRight') {
            this.echo(['->', `- .... . / -.- . -.-- / .. ... / --. . -- .. -. ..`])
            return
        }
    }
    public onEnter(value: string): void {
        if (value === 'gemini') {
            this.emit('game-state-event', { level: 'filesystem' })
            this.echo(['Password accepted.', 'Computer unlocked.'])
        } else if (value === 'the password') {
            return this.echo(['That is incorrect.', `- .... . / -.- . -.-- / .. ... / --. . -- .. -. ..`, ' ', ...DefaultLines])
        } else {
            return this.echo(['You need to enter the password to begin.', ' ', ...DefaultLines])
        }
    }
}