import { LevelHandler } from "./Base"

const ClaireContent = 'Stop here. None of the rest of the game is written.'

export class ClaireHandler extends LevelHandler {
    private isCompleted = false
    public password = 'regent'
    public start(): void {
        if (this.isCompleted) {
            this.echo([ClaireContent])
        } else {
            this.echo(['This file is locked.', 'Enter the password to read it.'])
        }
    }
    public onKey(key: string): void {
    }
    public onEnter(value: string): void {
        this.echo(['Incorrect password.'])
        this.emit('game-state-event', { level: 'filesystem' })
    }
}