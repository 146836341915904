import EventEmitter from "../../EventEmitter"

export type Level = 'start' | 'filesystem' | 'madison' | 'claire'

type EchoEvent = {
    type: 'echo-event',
    payload: { lines: string[] }
}
type GameStateEvent = {
    type: 'game-state-event',
    payload: { level: Level }
}
type ClearEvent = {
    type: 'clear-event',
    payload: {  }
}

export abstract class LevelHandler extends EventEmitter<EchoEvent | GameStateEvent | ClearEvent> {
    abstract password: string
    abstract start(): void
    public isStarted: boolean = false
    public get prefix() {
        return ''
    }

    protected echo(lines: string[]): void {
        this.emit('echo-event', { lines })
    }

    protected clear(): void {
        this.emit('clear-event', { })
    }

    public onKey(key: string): void {
    }
    public onEnter(value: string): void {
    }
}