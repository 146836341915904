import { LevelHandler } from "./Base"

const MadisonContent = 'Stop here. None of the rest of the game is written.'

export class MadisonHandler extends LevelHandler {
    private isCompleted = false
    public password = 'regent'
    public start(): void {
        if (this.isCompleted) {
            this.echo([MadisonContent])
        } else {
            this.echo(['This file is locked.', 'Enter the password to read it.'])
        }
    }
    public onKey(key: string): void {
    }
    public onEnter(value: string): void {
        if (value === 'regent') {
            this.isCompleted = true
            this.echo(['Password accepted.', ' ', MadisonContent])
        } else {
            this.echo(['Incorrect password.'])
        }
        this.emit('game-state-event', { level: 'filesystem' })
    }
}