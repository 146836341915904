import React from 'react'
import { StartHandler } from './Levels/0-StartLevel'
import { Level1Handler } from './Levels/1-Level1'
import { Level, LevelHandler } from './Levels/Base'
import { MadisonHandler } from './Levels/MadisonLevel'
import { ClaireHandler } from './Levels/ClaireLevel'

const LevelHandlers: Record<Level, LevelHandler> = {
    'start': new StartHandler(),
    'filesystem': new Level1Handler(),
    'madison': new MadisonHandler(),
    'claire': new ClaireHandler(),
}

const useTerminalLines = () => {
    const [lines, setLines] = React.useState<string[]>([])
    const appendLines = (newLines: string[]) => {
        setLines(lines => ([...lines, ...newLines]))
    }
    const clear = () => {
        setLines([])
    }
    return {lines, appendLines, clear}
}


export const useController = () => {
    const {lines, appendLines, clear} = useTerminalLines()
    const [gameState, setGameState] = React.useState<Level>('start')
    const levelHandler = React.useMemo(() => LevelHandlers[gameState], [gameState]) 
    const prefix = levelHandler.prefix

    React.useEffect(() => {
        levelHandler.on('echo-event', payload => appendLines(payload.lines))
        levelHandler.on('game-state-event', payload => setGameState(payload.level))
        levelHandler.on('clear-event', () => clear())
        levelHandler.start()
        levelHandler.isStarted = true
        return () => levelHandler.removeAllListeners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [levelHandler])

    const onKey = (key: string) => {
        levelHandler.onKey(key)
    }

    const onEnter = (value: string) => {
        const currentLine = `${prefix}${value}`
        appendLines([currentLine])
        if (value === '') {
            return
        }
        levelHandler.onEnter(value)
    }

    return {lines, prefix, onKey, onEnter}
}