import { LevelHandler } from "./Base"
import { DirectoryManager } from "./DirectoryManager"
import { Directory } from "./Files"
import { LocationsFile } from "./files/Locations"
import { README } from "./files/README_md"
import { ShutdownProcedures } from "./files/ShutdownProcedures"

const RootDirectory: Directory = {
    type: 'directory',
    name: '',
    content: [
        {
            type: 'document',
            name: 'README.md',
            content: README,
            password: 'password'
        },
        {
            type: 'directory',
            name: 'games',
            content: [
                {
                    type: 'executable',
                    name: 'snake',
                    content: 'snake game'
                },
                {
                    type: 'executable',
                    name: 'dash',
                    content: 'dash game'
                },
            ]
        },
        {
            type: 'directory',
            name: 'system',
            content: [
                {
                    type: 'directory',
                    name: 'config',
                    content: [
                        {
                            type: 'document',
                            name: 'locations.txt',
                            content: LocationsFile
                        }
                    ]
                },
                {
                    type: 'directory',
                    name: 'operations',
                    content: [
                        {
                            type: 'executable',
                            name: 'emergency_shutdown',
                            password: 'password',
                            content: 'things are bad'
                        },
                        {
                            type: 'document',
                            name: 'shutdown_procedures.txt',
                            content: ShutdownProcedures
                        }
                    ]
                }
            ]
        },
        {
            type: 'directory',
            name: 'users',
            content: [
                {
                    type: 'directory',
                    name: 'madison',
                    content: [
                        {
                            type: 'document',
                            name: 'notes.txt',
                            content: 'madison-level'
                        }
                    ]
                },
                {
                    type: 'directory',
                    name: 'claire',
                    content: [
                        {
                            type: 'document',
                            name: 'todo.txt',
                            content: 'claire-level'
                        }
                    ]
                }
            ]
        }
    ]
}

const Welcome = `Welcome to Project Gemini. Initializing System...

    1. Checking System Integrity... [OK]
    2. Loading Kernel Modules... [OK]
    3. Starting Network Services... [OK]
    4. Mounting File Systems... [OK]
    5. Loading User Interface...
    
Welcome Operator. 

Project Gemini - Version 1.2.4
Last System Update: May 22, 2023
System Time: 06:28:00 UTC
System Status: All Systems Operational.

"Protecting the fabric of time, one tick at a time."

Remember: Your actions matter. Our world is counting on you.

Type 'help' for a list of available commands.`


export class Level1Handler extends LevelHandler {
    public password = 'twenty'
    public get prefix() {
        const path = this.directoryManager.currentPath
        return path.substring(0, path.length - 1) + '>'
    }
    private directoryManager = new DirectoryManager('/', RootDirectory)

    public start(): void {
        if (!this.isStarted) {
            this.echo([' ', Welcome, ' '])
        }
    }
    public onEnter(value: string): void {
        const command = value.split(' ')[0]
        const args = value.split(' ').slice(1)
            
        if (command === 'help') {
            this.echo(['cat [filename] - view document', 'cd [directory] - change directory', 'clear - clear terminal history', 'ls - list files'])
        } else if (command === 'clear') {
            this.clear()
        } else if (command === 'ls') {
            this.echo(this.directoryManager.ls())
        } else if (command === 'cd' && args.length === 1) {
            const response = this.directoryManager.cd(args[0])
            if (response === null) {
                this.echo(['cd: no such directory'])
            }
        } else if (command === 'cat' && args.length === 1) {
            const content =this.directoryManager.cat(args[0])
            if (content) {
                if (content[0] === 'madison-level') {
                    this.emit('game-state-event', { level: 'madison' })
                } else if(content[0] === 'claire-level') {
                    this.emit('game-state-event', { level: 'claire' })
                } else {
                    this.echo(content)
                }
            } else {
                this.echo(['cat: no such file or directory'])
            }
        } else if(command === 'run' && args.length === 1) {
            const file = this.directoryManager.run(args[0])
            if (file) {
                if (file.password) {
                    this.echo(['You do not have permission to do that.', 'Only the system guardian can execute the emergency shutdown.'])
                } else {
                    this.echo(['Running ' + args[0] + '...'])
                    this.echo([file.content])
                }
            } else {
                this.echo(['run: no such file or directory'])
            }
        } else {
            this.echo(['Command not found. Get help if you need it.'])
        }
    }
}
