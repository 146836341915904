import { Directory } from "./Files"

export class DirectoryManager {
    public currentPath: string
    public rootDirectory: Directory
    constructor(currentPath: string, rootDirectory: Directory) {
        this.currentPath = currentPath
        this.rootDirectory = rootDirectory
    }

    public get currentDirectory() {
        return this.getDirectory(this.currentPath)
    }

    private getFile(path: string) {
        let currentDirectory = this.rootDirectory
        const pathParts = path.split('/').filter(Boolean)
        for (const name of pathParts) {
            const file = currentDirectory.content.find(file => file.name === name)
            if (file) {
                currentDirectory = file
            } else {
                return null
            }
        }
        return currentDirectory
    }
    private getDirectory(path: string) {
        const file = this.getFile(path)
        return file?.type === 'directory' ? file : null
    }
    private get files() {
        return this.currentDirectory?.content ?? []
    }
    private get fileNames() {
        return this.files.map(file => file.name)
    }

    public cd(directory: string) {
        if (directory === '/') {
            this.currentPath = '/'
        } else if (directory === '..') {
            this.currentPath = this.currentPath.split('/').slice(0, -2).join('/') + '/'
        } else if (this.fileNames.includes(directory)) {
            const newPath = this.currentPath + directory + '/'
            const newDirectory = this.getDirectory(newPath)
            if (newDirectory) {
                this.currentPath = newPath
            } else {
                return null
            }
        } else {
            return null
        }
        return this.currentPath
    }
    public ls() {
        return [...this.fileNames]
    }
    public cat(fileName: string) {
        const file = this.files.find(file => file.name === fileName)
        if (file?.type === 'document') {
            return [file.content]
        }
        return null
    }
    public run(fileName: string) {
        const file = this.files.find(file => file.name === fileName)
        if (file?.type === 'executable') {
            return file
        }
        return null
    }
    public pwd() {
        return [this.currentPath]
    }
}