export const LocationsFile = `****
***
.

******
*******
*********
***
*********
,
-
********
*********
.
****
**
****
*******
*
****`