import React from 'react'
import styled from 'styled-components'
import { useController } from './GameController'
import InputLine from './InputLine'
import Line from './Line'

const Container = styled.div`
    font-size: 18px;
    margin: 100px auto;
    width: 600px;
    height: 400px;
    color: #28FDCD;
    text-shadow: 0 0 3px #33FF33, 0 0 8px #00ff66, 0 0 8px #00ff66;
`


const Terminal = () => {
    const {lines, prefix, onKey, onEnter} = useController()
    return (
        <Container>
            {lines.map((line, index) => <Line key={index} text={line} />)}
            <InputLine prefix={prefix} onEnter={onEnter} onKey={onKey} />
        </Container>
    )
}

export default Terminal;