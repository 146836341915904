export const README = `Welcome to Project Gemini - Earth's Final Line of Defense.

Project Gemini is a powerful global defense system designed to protect Earth from extraterrestrial threats. Its vast computational abilities and automated protocols have been instrumental in keeping our world safe.

However, every system, no matter how powerful, can encounter difficulties. In the event of a critical system error or potential misuse, a failsafe has been designed into the system, allowing a total system shutdown.

Please note, the shutdown procedure requires a unique code. Without this, the shutdown will not proceed. This code is not stored in plain sight - we cannot risk it falling into the wrong hands. Seek the files that guide you in the dance of stars and numbers.

Remember, the stakes are high. Misuse of this system or failure to act in a time of crisis could result in grave consequences.

Stay vigilant.`